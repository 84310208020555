.container {
  @apply h-auto flex flex-col mt-6 w-full;
}

.products {
  @apply flex flex-1 gap-1 md:gap-4 overflow-x-scroll;
  scroll-snap-type: x mandatory;
}

@media (--tablet) {
  .title {
    @apply text-2xl;
  }
}