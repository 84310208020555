.overlay {
  @apply fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center;
  z-index: 10001;
}

.content {
  @apply bg-white px-6 py-4 md:py-4 rounded-lg w-full h-auto md:max-w-3xl max-w-md relative;
}

.closeButton {
  @apply absolute top-2 right-2 bg-blackAlt text-white rounded-full z-10;
}